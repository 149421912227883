/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";

/*COMPONENT*/
import SpinnerSimpleBlue from "../common/SpinnerSimpleBlue";

/*REDUX*/
import {
  commentToLayer,
  getDetailLayerByLinkWOgeojson,
} from "../../actions/formActions";

/*PICTURE*/
import BackIcon from "../common/BackIcon";

/*FUNCTION*/
import isEqual from "../../validation/is-equal";
import uuid from "../../validation/uuid";

/*DATA*/

/*CONST*/

class LayerChat extends Component {
  state = { geo_layer_wo_geojson: {}, text: "" };
  interval_chat = null;
  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };
  componentDidMount() {
    const geo_layer_link = this.props.match.params.geo_layer_link;
    this.props.getDetailLayerByLinkWOgeojson(geo_layer_link);
    this.scrollToBottom();
    this.interval_chat = setInterval(() => {
      this.props.getDetailLayerByLinkWOgeojson(geo_layer_link);
    }, 1000);
  }
  componentDidUpdate(prevProps) {
    if (
      !isEqual(
        this.props.form.geo_layer_wo_geojson,
        prevProps.form.geo_layer_wo_geojson
      )
    ) {
      this.scrollToBottom();
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.interval_chat);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEqual(
        nextProps.form.geo_layer_wo_geojson,
        prevState.geo_layer_wo_geojson
      )
    ) {
      return {
        geo_layer_wo_geojson: nextProps.form.geo_layer_wo_geojson,
      };
    } else return null;
  }
  //Local change
  handleChange = (e) => {
    e.preventDefault();
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  //Submit
  handleSubmit = (e) => {
    e.preventDefault();
    const { user } = this.props.auth;
    const { _id, name, full_name, profile_picture } = user;
    const { geo_layer_wo_geojson, text } = this.state;
    const geo_layer_id = geo_layer_wo_geojson._id;
    const comment = {
      text: text,
      key: uuid(),
      isEdited: false,
      images: [],
      user_tag: [],
      user: {
        _id,
        name,
        full_name,
        profile_picture,
      },
    };
    const newItem = {
      geo_layer_id: geo_layer_id,
      comment,
    };
    this.props.commentToLayer(newItem);
    this.setState({ text: "" });
  };
  render() {
    const { loadingProcess } = this.props.form;
    const { user } = this.props.auth;
    const user_id = user._id;
    const { geo_layer_wo_geojson } = this.state;
    const text_state = this.state.text;
    const { name, link } = geo_layer_wo_geojson;
    const comments = geo_layer_wo_geojson.comments
      ? geo_layer_wo_geojson.comments
      : [];

    let buttonContent;
    if (loadingProcess) {
      buttonContent = (
        <SpinnerSimpleBlue
          width={40}
          unik="loading_create_layer"
          marginTop="0px"
          center={false}
          border={0.1}
        />
      );
    } else {
      buttonContent = (
        <button
          type="submit"
          className="button_standard margin_right"
          style={{ marginTop: "10px" }}
        >
          Submit
        </button>
      );
    }
    const comments_content = comments.map(({ user, text, date }, idx) => {
      const { name, full_name, _id } = user;
      const name_string = full_name ? full_name : name;
      const right_or_left = _id === user_id ? "chat_right" : "chat_left";
      const backgroundColor = _id === user_id ? "chat_user" : "chat_other";
      const colorUserName =
        _id === user_id ? "chat_user_color" : "chat_other_color";
      return (
        <div className={right_or_left} key={idx}>
          <div className={`chat_bubble ${backgroundColor}`}>
            <div className={`chat_name ${colorUserName}`}>{name_string}</div>
            <div className="chat_text">
              {text}
              <br />
              <div style={{ fontSize: "10px" }}>{moment(date).from()}</div>
            </div>
          </div>
        </div>
      );
    });
    return (
      <div className="chat_box">
        <div className="chat_main">
          <div className="chat_title">
            <div className="center_vertical left_horizontal">
              <Link to="/menu/chat" className="button_circle">
                <div className="button_circle_inside">
                  <BackIcon color="#000000" />
                </div>
              </Link>
              <div className="chat_title_text">Chat: {name}</div>
            </div>
          </div>
          <div className="chat_conversation_box">
            {comments_content}
            <div
              style={{ float: "left", clear: "both" }}
              ref={(el) => {
                this.messagesEnd = el;
              }}
            ></div>
          </div>
          <div className="chat_writing">
            <form onSubmit={this.handleSubmit.bind(this)}>
              <textarea
                type="text"
                name="text"
                id="text"
                value={text_state}
                onChange={this.handleChange}
                rows="4"
              />
              {buttonContent}
              <Link to={`/${link}`} className="button_standard">
                Isi Form
              </Link>
            </form>
          </div>
        </div>
        <style>{`
        .nav_bottom{ display:none;}
     `}</style>
      </div>
    );
  }
}
LayerChat.propTypes = {
  project: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  commentToLayer: PropTypes.func.isRequired,
  getDetailLayerByLinkWOgeojson: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  project: state.project,
  form: state.form,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  commentToLayer,
  getDetailLayerByLinkWOgeojson,
})(LayerChat);
